body {
  margin-top: 60px;
}



/* Buttons */

.btn-primary {
  color: #232a3b;
  background-color: #eaac52;
  border-color: #eaac52;
}

.btn-primary:hover {
  color: #232a3b;
  background-color: #ce9748;
  border-color: #ce9748;
}

.btn-outline-primary {
  color: #232a3b;
  background-color: transparent;
  border-color: #eaac52;
}

.btn-outline-primary:hover {
  color: #232a3b;
  background-color: transparent;
  border-color: #232a3b;
}

.btn-secondary {
  color: #eaac52;
  background-color: #232a3b;
  border-color: #232a3b;
}

.btn-secondary:hover {
  color: #eaac52;
  background-color: #2d364c;
  border-color: #2d364c;
}

.btn-outline-secondary {
  color: #232a3b;
  background-color: transparent;
  background-image: none;
  border-color: #232a3b;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #232a3b;
  border-color: #232a3b;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #232a3b;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #232a3b;
  border-color: #232a3b;
}



/* SVG and Secondary BG */
.bg-secondary-svg {
  fill: #fafbff;
}

.bg-secondary {
  background-color: #fafbff;
}

.bg-secondary-svg {
  fill: #fafbff;
}



/* Typography */
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .text-blue {
  color: #232a3b;
}

/* Nav */
.bg-dark {
  background-color: #232a3b !important;
}